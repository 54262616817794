import { Row, Col, Container } from "react-bootstrap";
import Hostnetlogo from "../../assets/Hostnetlogo";
import {
  ActionButton,
  BoardAvisosDominio,
  BolderText,
  DomainOption,
  FaQuestionCircle,
  Header,
  HeaderRow,
  InputBox,
  LoadingText,
  MainContainer,
  NextStep,
  Select,
  StyledLoopIcon,
  ValidationDomainText,
} from "./styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import axios from "axios";

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams("");
  const [domainName, setDomainName] = useState<string>("");
  const [extension, setExtension] = useState<string>("com");
  const [extensionList, setExtensionList] = useState<Array<string>>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [fetched, setFetched] = useState<boolean>(false);
  const [isDomainValid, setIsDomainValid] = useState<boolean>(false);
  const [DomainReturn, setDomainReturn] = useState<any>({});
  const [hasSpecialCharacter, setHasSpecialCharacter] =
    useState<boolean>(false);
  const [alternativeDomain, setAlternativeDomain] = useState<Array<any>>([]);
  const [currentAbortFunc, setCurrentAbortFunc] = useState<any>();

  const urlDomainValueHandle = () => {
    const domainUrl = searchParams.get("nome");
    if (domainUrl) {
      const name = domainUrl.split(".");
      setDomainName(name[0]);
      if (name[2]) {
        setExtension(`${name[1]}.${name[2]}`);
        verifyDomain(name[0], `${name[1]}.${name[2]}`);
      } else {
        setExtension(`${name[1]}`);
        verifyDomain(name[0], name[1]);
      }
    }
  };

  const getExtensions = () => {
    axios
      .get("https://asn.apihn.co/api/domains")
      .then((response) => {
        const fixedExtensions = [
          "com",
          "com.br",
          "net",
          "org",
          "rio",
          "rio.br",
        ];
        fixedExtensions.forEach((item) => {
          const indexArray = response.data.data.indexOf(item);
          if (indexArray > -1) {
            response.data.data.splice(indexArray, 1);
          }
        });
        setExtensionList([...fixedExtensions, ...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyDomain = (DomainName: string, DomainExtension: string) => {
    const controller = new AbortController();
    if (DomainName.length > 0) {
      setFetched(false);
      setIsFetching(true);
      setHasSpecialCharacter(false);
      axios
        .get(
          `https://asn.apihn.co/api/domain_name?domain=${DomainName.toLocaleLowerCase()}.${DomainExtension}`,
          {
            signal: controller.signal,
          }
        )
        .then((response) => {
          setIsFetching(false);
          setIsDomainValid(response.data.data.disponivel);
          setDomainReturn({
            domainName: response.data.data.nome,
            extension: response.data.data.extensao,
          });
          //Verifica as outras extensões em caso de indisponibilidade do domínio.
          if (!response.data.data.disponivel) {
            checkDomainOption();
          }
          setFetched(true);
        })
        .catch(function (err) {
          if (axios.isCancel(err)) {
            console.log(
              "Previous request canceled, new request is send",
              err.message
            );

            setFetched(false);
          } else {
            setHasSpecialCharacter(true);
            setIsFetching(false);
          }
        });
    }
    setCurrentAbortFunc(controller);
  };

  //Verifica as outras extensões em caso de indisponibilidade do domínio.
  const checkDomainOption = () => {
    setAlternativeDomain([]);
    const fixedExtensions = ["com", "com.br", "net", "org", "info", "rio"];
    fixedExtensions.forEach((item) => {
      axios
        .get(
          `https://asn.apihn.co/api/domain_name?domain=${domainName.toLocaleLowerCase()}.${item}`
        )
        .then((response) => {
          //console.log(response.data.data.dominio);
          if (response.data.data.disponivel) {
            setAlternativeDomain((prev) => [
              ...prev,
              {
                domainName: response.data.data.nome,
                extension: response.data.data.extensao,
              },
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  const onChangeHandleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainName(e.target.value);
    setFetched(false);
    setHasSpecialCharacter(false);
    setIsDomainValid(false);
  };

  useEffect(() => {
    urlDomainValueHandle();
    getExtensions();
  }, []);

  return (
    <>
      <Header>
        <Container className="ps-4 pe-4">
          <HeaderRow>
            <Col
              md={3}
              className="d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <a
                href="https://www.hostnet.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                <Hostnetlogo />
              </a>
            </Col>
            <Col
              md={6}
              className="d-none d-md-flex align-items-center justify-content-center"
            >
              <h1>Disponibilidade de domínio</h1>
            </Col>
            <Col
              md={3}
              className="d-none d-md-flex flex-column  align-items-end justify-content-center"
            >
              <a
                href="https://www.hostnet.com.br/atendimento-ao-cliente/"
                target="_blank"
                rel="noreferrer"
              >
                <FaQuestionCircle />
              </a>
            </Col>
          </HeaderRow>
        </Container>
      </Header>
      <main>
        <MainContainer className="mt-5 mb-5 ps-md-5 pe-md-5 ps-4 pe-4">
          <Row>
            <h4>Informe o domínio que deseja utilizar:</h4>
          </Row>
          <Row className="mt-5">
            <BoardAvisosDominio className="p-4">
              <p>
                <HelpOutlineIcon sx={{ color: "#1D2393" }} /> Para domínios
                terminados em .br, o documento fornecido (CNPJ ou CPF) deve
                estar regular na Receita Federal e, preferencialmente, não estar
                em uso no Registro.br.
              </p>
              <p className="mt-2">
                <HelpOutlineIcon sx={{ color: "#1D2393" }} /> Algumas categorias
                de domínios nacionais são destinadas a pessoa jurídica e exigem
                o uso de CNPJ. Confira a lista{" "}
                <a
                  href="https://registro.br/dominio/categoria.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  aqui
                </a>
                .
              </p>
            </BoardAvisosDominio>
          </Row>
          <Row className="mt-5">
            <Col md={9} className=" p-0">
              <InputBox
                className={`${
                  fetched ? (isDomainValid ? "green" : "red") : ""
                }`}
                onBlur={() => {
                  if (!fetched) verifyDomain(domainName, extension);

                  if (currentAbortFunc) {
                    currentAbortFunc.abort();
                  }
                  //setIsFetching(true);
                }}
              >
                <span>www.</span>
                <input
                  type="text"
                  value={domainName}
                  className={hasSpecialCharacter ? "yellow" : ""}
                  onChange={(e) => {
                    onChangeHandleInput(e);
                  }}
                />
                <Select
                  name="Extensao"
                  id="Extensao"
                  value={extension}
                  onChange={(e) => {
                    setExtension(e.target.value);
                    verifyDomain(domainName, e.target.value);
                    if (currentAbortFunc) {
                      currentAbortFunc.abort();
                    }
                    //setIsFetching(true);
                  }}
                >
                  {extensionList.map((item, index) => (
                    <option key={index} value={item}>
                      .{item}
                    </option>
                  ))}
                </Select>
              </InputBox>
            </Col>
            <Col className="p-0 ps-md-1 mt-4 mt-sm-0">
              <ActionButton
                disabled={isFetching}
                onClick={() => verifyDomain(domainName, extension)}
              >
                {isFetching ? <StyledLoopIcon /> : "Verificar Disponibilidade"}
              </ActionButton>
            </Col>
          </Row>
          <Row className="mt-2">
            {isFetching && (
              <LoadingText>
                <StyledLoopIcon /> Aguarde um momento por gentileza.
              </LoadingText>
            )}
          </Row>
          <Row className="mt-5">
            {fetched ? (
              <BoardAvisosDominio className="p-4">
                <ValidationDomainText
                  className={isDomainValid ? "green" : "red"}
                >
                  {isDomainValid ? (
                    <>
                      <BolderText>
                        {" "}
                        {DomainReturn.domainName.toLocaleLowerCase()}.
                        {DomainReturn.extension}
                      </BolderText>{" "}
                      está disponível
                    </>
                  ) : (
                    <>
                      <BolderText>
                        {" "}
                        {DomainReturn.domainName.toLocaleLowerCase()}.
                        {DomainReturn.extension}{" "}
                      </BolderText>{" "}
                      não está disponível para registro
                    </>
                  )}
                  .
                </ValidationDomainText>
              </BoardAvisosDominio>
            ) : (
              ""
            )}
            {hasSpecialCharacter && (
              <BoardAvisosDominio className="p-4">
                <ValidationDomainText className="yellow">
                  <WarningIcon />
                  {
                    <>
                      {" "}
                      O nome{" "}
                      <BolderText>
                        {domainName.toLocaleLowerCase()}
                      </BolderText>{" "}
                      é invalido, o mesmo contém caractere(s) especial(is)
                      inválido(s).
                    </>
                  }
                </ValidationDomainText>
              </BoardAvisosDominio>
            )}
          </Row>

          <Row>
            {!isDomainValid && fetched && alternativeDomain.length > 0 ? (
              <BoardAvisosDominio className="p-4">
                <p style={{ color: "#1d2393", fontSize: "1rem" }}>
                  Outras opções disponíveis.
                </p>
                {alternativeDomain.map((item: any, index: any) => {
                  return (
                    <DomainOption key={index}>
                      <BolderText>
                        <p>
                          {item.domainName}.{item.extension}
                        </p>
                      </BolderText>
                      <NextStep
                        disabled={false}
                        onClick={() =>
                          window.open(
                            "https://assine.hostnet.com.br/?produto=dominio.registrar"
                          )
                        }
                      >
                        Comprar .{item.extension}
                      </NextStep>
                    </DomainOption>
                  );
                })}
              </BoardAvisosDominio>
            ) : (
              ""
            )}
          </Row>
          <Row className="mt-5">
            <Col></Col>
            <Col className="p-0 d-flex align-items-end justify-content-end">
              <NextStep
                disabled={!isDomainValid}
                onClick={() =>
                  window.open(
                    "https://assine.hostnet.com.br/?produto=dominio.registrar"
                  )
                }
              >
                Próximo passo
              </NextStep>
            </Col>
          </Row>
        </MainContainer>
      </main>
    </>
  );
};
export default Home;
